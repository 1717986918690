<template>
  <!-- eslint-disable -->
  <div class="bg-white center-container">
    <div class="checkout-main-container">
      <div class="left-container">
        <div class="info-container">
          <p
            style="font-size: 30px; font-weight: bold"
            class="right-align-text"
          >
            Checkout
          </p>
          <p style="font-weight: bold">Is all your information correct?</p>
          <p>
            With a honely pro subscription you’ll be equipped with the most
            powerful real estate data on the market. Access and download
            comprehensive property reports, home forecasts, neighborhood &
            moving trends, and more with a simple search.
          </p>
        </div>
        <div class="payment-container">
          <p style="font-size: 30px; font-weight: bold">Payment</p>
          <template v-if="paymethodsLoading">
            <div v-for="n in 3" class="stripe-paymethod-wrapper">
              <vue-skeleton-loader type="circle" :width="16" :height="16" />
              <vue-skeleton-loader
                type="rect"
                radius="8"
                :width="80"
                :height="20"
              />
              <vue-skeleton-loader
                type="rect"
                radius="8"
                :width="200"
                :height="20"
              />
            </div>
          </template>
          <template v-else>
            <div class="payment-spacing-container" v-if="paymethods.length > 0">
              <p>Payment Method</p>
              <div
                v-for="paymethod in paymethods"
                :key="paymethod.id"
                class="stripe-paymethod-wrapper"
                @click="handleSelectPaymethod(paymethod.id)"
              >
                <input
                  type="radio"
                  v-model="selectedPaymethodId"
                  :value="paymethod.id"
                  style="margin-right: 5px"
                />
                <span style="margin-right: 5px">
                  <!-- {{
                  paymethod.brand.toUpperCase()
                }} -->
                  ****
                </span>
                <span style="margin-right: 5px">{{ paymethod.last4 }}</span>
                <div class="card-image-wrapper">
                  <img
                    v-if="paymethod.brand.toLowerCase() === 'visa'"
                    src="site_images/icons/visa.png"
                    alt="Visa"
                  />
                  <img
                    v-else-if="paymethod.brand.toLowerCase() === 'mastercard'"
                    src="site_images/icons/mastercard.png"
                    alt="Mastercard"
                  />
                  <img
                    v-else-if="
                      paymethod.brand.toLowerCase() === 'american express'
                    "
                    src="site_images/icons/amex.png"
                    alt="American Express"
                  />
                  <img
                    v-else-if="paymethod.brand.toLowerCase() === 'discover'"
                    src="site_images/icons/discover.png"
                    alt="Discover"
                  />
                  <img
                    v-else-if="paymethod.brand.toLowerCase() === 'jcb'"
                    src="site_images/icons/jcb.png"
                    alt="JCB"
                  />
                  <img
                    v-else-if="paymethod.brand.toLowerCase() === 'unionpay'"
                    src="site_images/icons/cup.png"
                    alt="UnionPay"
                  />
                  <span v-else>
                    {{ paymethod.brand.toUpperCase() }}
                  </span>
                </div>
              </div>
            </div>
          </template>
          <div
            @click="toggleStripeCardFormShow(true)"
            class="stripe-card-add-btn-container"
          >
            <i
              class="fa fa-plus-circle"
              style="margin-right: 5px"
              aria-hidden="true"
            ></i>
            <button v-if="paymethods.length === 0" class="stripe-card-add-btn">
              Enter a credit card
            </button>
            <button v-else class="stripe-card-add-btn">Add another card</button>
          </div>
        </div>
        <stripe-card-form
          :show="showStripeCardForm"
          @toggleShow="toggleStripeCardFormShow"
          @successAdded="handlePaymethodAdded"
        />
      </div>
      <div class="right-container">
        <p style="font-size: 30px">Order Summary</p>
        <!-- <div class="address-info-container">
          <div class="image-container">
            <img :src="image" alt="" />
          </div>
          <div class="address-container">
            <i
              class="fa fa-map-marker"
              style="color: grey; margin-right: 5px"
            />
            <p>{{ displayAddress }}</p>
          </div>
        </div> -->
        <div class="price-container">
          <div class="price-row">
            <p>Monthly Subscription</p>
            <p>$2.99</p>
          </div>
          <div class="price-row" v-if="hasFreeTrial === true">
            <p>30-day trial (1st month free)</p>
            <p id="subscription_discount">-$2.99</p>
          </div>

          <div class="price-row">
            <p>Total</p>
            <p v-if="hasFreeTrial === true">$0.00</p>
            <p v-else>$2.99</p>
          </div>
        </div>
        <div class="subscription-container">
          <!-- <div class="left-container1">
            <input type="checkbox" v-model="isSubscribing" />
          </div> -->
          <div class="right-container">
            <p style="margin-bottom: 10px; color: black">
              Monthly Subscription
            </p>

            <p style="font-size: 12px;" v-if="hasFreeTrial">
              Only $2.99/month after trial. Cancel anytime.
            </p>
            <p style="font-size: 12px;" v-else>
              You will be billed every 30 days for $2.99 until you cancel the
              subscription
            </p>
          </div>
        </div>
        <div
          v-if="paymethods.length > 0 && selectedPaymethodId"
          style="width: 100%; display: flex; justify-content: center"
        >
          <div
            v-if="paymentLoading"
            style="margin-bottom: 20px; margin-top: 50px"
          >
            Loading...
          </div>
          <button v-else class="main-button" @click="submitPurchase()">
            Purchase
          </button>
        </div>
        <div v-else style="width: 100%; display: flex; justify-content: center">
          <button class="main-button-disable" disabled style="width: 100%">
            Purchase
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import VueSkeletonLoader from "skeleton-loader-vue";
import axios from "axios";

export default {
  name: "Checkout",
  props: {
    searchQuery: String,
  },
  components: {
    VueSkeletonLoader,
    StripeCardForm: () =>
      import("@/components/forecastDataSubscription/StripeCardForm"),
    StripeCheckout,
  },
  data() {
    return {
      showStripeCardForm: false,
      paymethodsLoading: true,
      paymethods: [],
      selectedPaymethodId: null,
      paymethodDefaultChecked: false,
      hasFreeTrial: false,
      subscriptionPrice: 1499,
      subScriptionError: null,
      isSubscribing: this.$route.query.sub === "false" ? false : true,
      promoCode: "",
      promoCodeFlag: false,
      promoCodeError: null,
      displayAddress: decodeURI(this.$route.query.address),
      property_id: "",
      image: null,
      validImage: null,
      paymentLoading: false,
      paymentError: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["isCognitoUserLoggedIn", "cognitoUser"]),
  },
  mounted() {
    if (this.isCognitoUserLoggedIn) {
      console.log("get payment methods");
      this.getPaymethods();
      this.getForecastData();
      this.getUserDetail();
    } else {
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    getUserDetail() {
      axios
        .get("https://api.honely.com/lookup-test/user_profile", {
          params: {
            email: this.$store.getters["auth/cognitoUser"].attributes.email,
          },
        })
        .then((response) => {
          this.hasFreeTrial = response.data.free_trial;
        })
        .catch((error) => {
          console.log("[ERROR] Failed to fetch user data");
          console.log(error.response.data.errorMessage);
        });
    },
    getForecastData() {
      let body = {
        address: this.$route.query.address,
      };
      axios
        .get("https://api.honely.com/searches/dev/v1/forecast", {
          headers: {
            Authorization:
              "Bearer " + this.cognitoUser.signInUserSession.idToken.jwtToken,
          },
          params: body,
        })
        .then((res) => {
          if (res.data.access === true) {
            this.$router.push({
              path: "forecast",
              query: { address: this.$route.query.address },
            });
          }

          this.property_id = res.data.property_forecast.property_id;

          console.log(res);
          this.checkImage(res.data);
        });
    },
    handlePaymethodAdded() {
      this.getPaymethods();
    },
    handleSelectPaymethod(paymethodId) {
      this.selectedPaymethodId = paymethodId;
    },
    getPaymethods() {
      this.paymethodsLoading = true;
      axios
        .get("https://api.honely.com/dev/payments/v2/payment-methods", {
          headers: {
            Authorization:
              "Bearer " + this.cognitoUser.signInUserSession.idToken.jwtToken,
          },
        })
        .then((response) => {
          this.paymethods = response.data.data;
          this.selectedPaymethodId = response.data.data.find(
            (paymethod) => paymethod.default
          )?.id;
          this.paymethodDefaultChecked = this.selectedPaymethodId
            ? true
            : false;
          this.paymethodsLoading = false;
        })
        .catch((error) => {
          this.paymethodsLoading = false;
          console.log(error);
        });
    },
    toggleStripeCardFormShow(value) {
      this.showStripeCardForm = value;
    },
    submitPurchase() {
      // If the user wants to subscribe
      // if (this.property_id) {
      //   let header = {
      //     headers: {
      //       Authorization:
      //         "Bearer " + this.cognitoUser.signInUserSession.idToken.jwtToken,
      //     },
      //   };
      //   this.paymentLoading = true;
      //   if (this.isSubscribing) {
      //     console.log("subscribing");
      //     let body = {
      //       "payment-method": this.selectedPaymethodId,
      //       "default-pm": true,
      //       "subscription-type": "HONELY-SINGLE-PROPERTY",
      //       "property-id": this.property_id,
      //     };
      //     axios
      //       .post(
      //         "https://api.honely.com/dev/payments/v2/create-subscription",
      //         body,
      //         header
      //       )
      //       .then((res) => {
      //         console.log(res);
      //         if (res.status === 200) {
      //           // this.$router.push({
      //           //   path: "forecast",
      //           //   query: { address: this.$route.query.address },
      //           // });
      //           console.log("Redirect to Subscription Thank you page");
      //           // this.$router.push({
      //           //   path: "thankYouSub",
      //           //   query: { address: this.$route.query.address, type: 'subscription' },
      //           // });
      //           window.location.href =
      //             "/thankYouSub?address=" +
      //             this.$route.query.address +
      //             "&type=subscription";
      //         }
      //       });
      //   }
      //   // } else {
      //   //   console.log("single payment");
      //   //   let body = {
      //   //     amount: 299,
      //   //     "payment-method": this.selectedPaymethodId,
      //   //     "default-pm": true,
      //   //     "property-id": this.property_id,
      //   //   };
      //   //   axios
      //   //     .post(
      //   //       "https://api.honely.com/dev/payments/v1/create-payment",
      //   //       body,
      //   //       header
      //   //     )
      //   //     .then((res) => {
      //   //       if (res.status === 200) {
      //   //         console.log(res);
      //   //         // this.$router.push({
      //   //         //   path: "forecast",
      //   //         //   query: { address: this.$route.query.address },
      //   //         // });
      //   //         console.log("Redirect to Purchase Thank you page");
      //   //         // this.$router.push({
      //   //         //   path: "thankYouPurchase",
      //   //         //   query: { address: this.$route.query.address, type: 'purchase' },
      //   //         // });
      //   //         window.location.href =
      //   //           "/thankYouPurchase?address=" +
      //   //           this.$route.query.address +
      //   //           "&type=purchase";
      //   //       }
      //   //     });
      //   // }
      //   this.paymentLoading(false);
      // } else {
      //   console.log("error property id missing");
      //   return;
      // }

      let header = {
        headers: {
          Authorization:
            "Bearer " + this.cognitoUser.signInUserSession.idToken.jwtToken,
        },
      };
      this.paymentLoading = true;

      let body = {
        "payment-method": this.selectedPaymethodId,
      };
      axios
        .post(
          "https://api.honely.com/dev/payments/v2/create-subscription",
          body,
          header
        )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            // this.$router.push({
            //   path: "forecast",
            //   query: { address: this.$route.query.address },
            // });
            console.log("Redirect to Subscription Thank you page");
            // this.$router.push({
            //   path: "thankYouSub",
            //   query: { address: this.$route.query.address, type: 'subscription' },
            // });
            window.location.href =
              "/thankYouSub?address=" +
              this.$route.query.address +
              "&type=subscription";
          }
        });
      this.paymentLoading(false);
    },

    checkImage(forecast) {
      console.log("geting image", forecast);
      const self = this;
      if (forecast && forecast.property_forecast) {
        if (forecast.property_forecast.listing_key) {
          // Get ListHub image
          self.image =
            "https://listhub-property-images.s3.amazonaws.com/" +
            forecast.property_forecast.listing_key +
            "_1.jpg";
        } else if (
          forecast.property_forecast.latitude &&
          forecast.property_forecast.longitude
        ) {
          const url =
            "https://maps.googleapis.com/maps/api/streetview?size=600x400&location=" +
            forecast.property_forecast.latitude +
            "," +
            forecast.property_forecast.longitude +
            "&radius=100&return_error_code=true&source=outdoor&key=" +
            this.$mapsKey;
          fetch(url, {
            method: "GET",
            headers: {},
          })
            .then(function(response) {
              if (!response.ok) {
                throw Error(response.status);
              }
              return response.blob;
            })
            .then(function(blob) {
              self.image = url;
              self.validImage = true;
            })
            .catch((err) => {
              self.validImage = true;
              self.image = "/site_images/listing_default_image.png";
              console.log("[ERROR] Google image API failed =>", err);
            });
        }
      }
    },
  },
};
</script>

<style>
#subscription_discount {
  color: red;
}

.stripe-card-add-btn-container {
  cursor: pointer;
  width: auto;
  display: flex;
  align-items: center;
}
.stripe-card-add-btn-container:hover {
  color: black;
  background: transparent;
}
.stripe-card-add-btn {
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  /* color: #000; */
  font-size: 12px;
  margin: 20px 0;
}
.stripe-card-add-btn:hover {
  text-decoration: underline;
  color: black;
  background: transparent;
}
.stripe-paymethod-wrapper {
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
}
.payment-spacing-container {
  padding: 40px 0;
}
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkout-main-container {
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  padding: 0 60px;
}
.main-button {
  background-color: #24cb43;
  color: white;
  border: none;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
}
.main-button-disable {
  background-color: grey;
  color: white;
  border: none;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
  cursor: not-allowed;
}
.checkout-main-container p {
  margin: 0;
}
.right-align-text {
  text-align: center;
}
.checkout-main-container .left-container {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.info-container {
  display: flex;
  flex-direction: column;
}
.checkout-main-container .right-container {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.right-container .address-info-container {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  width: 100%;
}
.right-container .address-info-container .address-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.right-container .address-info-container .address-container p {
  margin-bottom: 0px !important;
}
.right-container .address-info-container .image-container {
  height: 100px;
  width: 150px;
  border-radius: 10px;
  overflow: hidden;
}
.right-container .address-info-container .image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.payment-container {
  padding: 40px 0;
}
.price-container {
  width: 100%;
  padding: 20px 0;
}
.price-container .price-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.subscription-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.subscription-container .right-container {
  width: 100%;
}
.subscription-container .left-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}
.subscription-container .left-container1 input {
  height: 20px;
  width: 20px;
}

.card-image-wrapper {
  /* max-height: 35px; */
  max-width: 40px;
}
@media only screen and (max-width: 700px) {
  .checkout-main-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  .checkout-main-container .left-container {
    width: 100%;
  }
  .checkout-main-container .right-container {
    width: 100%;
    padding-top: 0px;
  }
  .right-align-text {
    text-align: center;
  }
  .subscription-container .left-container1 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 20%;
  }
}
</style>
